<template>
  <div class="bottom-nav">
    <router-link :to="item.link" v-for="(item, index) of menu" :key="index">
      <div
        class="bottom-nav__item"
        :class="{
          'bottom-nav__item--active':
            item.link == '/patient'
              ? $route.path.includes(`/${$i18n.locale}`)
                ? $route.path == `/${$i18n.locale}${item.link}`
                : $route.path == item.link
              : $route.path.includes('/profile') && $route.query.tab
              ? item.link.includes($route.query.tab)
              : $route.path.includes(item.link),
        }"
      >
        <icons
          :icon="item.icon"
          :color="
            item.link == '/patient'
              ? $route.path.includes(`/${$i18n.locale}`)
                ? $route.path == `/${$i18n.locale}${item.link}`
                  ? '#1F3C87'
                  : '#00a4b3'
                : $route.path == item.link
                ? '#1F3C87'
                : '#00a4b3'
              : $route.path.includes('/profile') && $route.query.tab
              ? item.link.includes($route.query.tab)
                ? '#1F3C87'
                : '#00a4b3'
              : $route.path.includes(item.link)
              ? '#1F3C87'
              : '#00a4b3'
          "
        />
        <p>
          {{ item.name }}
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
import icons from "@/components/elements/Icons.vue";
export default {
  components: {
    icons,
  },
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";
.bottom-nav {
  display: none;
  box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  // border-radius: 30px 30px 0px 0px;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  padding: 0 12px;
  background: white;
  border-top: 1px solid #e6e6e6;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 7;
  @media screen and (max-width: 960px) {
    display: flex;
  }
  a {
    text-decoration: none;
  }
  &__item {
    display: flex;
    width: 64px;
    height: 64px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 400;
    font-size: 8px;
    line-height: 8px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    padding: 8px 0px;
    color: $main_2;
    text-align: center;
    position: relative;
    &--active {
      // border-radius: 50%;
      // transform: scale(1.1);
      // box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
      color: #1f3c87;
      font-weight: bold;
    }
    p {
      margin-bottom: 0;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 600px) {
  .bottom-nav {
    position: fixed;
  }
}
</style>
