var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-nav"},_vm._l((_vm.menu),function(item,index){return _c('router-link',{key:index,attrs:{"to":item.link}},[_c('div',{staticClass:"bottom-nav__item",class:{
        'bottom-nav__item--active':
          item.link == '/patient'
            ? _vm.$route.path.includes(`/${_vm.$i18n.locale}`)
              ? _vm.$route.path == `/${_vm.$i18n.locale}${item.link}`
              : _vm.$route.path == item.link
            : _vm.$route.path.includes('/profile') && _vm.$route.query.tab
            ? item.link.includes(_vm.$route.query.tab)
            : _vm.$route.path.includes(item.link),
      }},[_c('icons',{attrs:{"icon":item.icon,"color":item.link == '/patient'
            ? _vm.$route.path.includes(`/${_vm.$i18n.locale}`)
              ? _vm.$route.path == `/${_vm.$i18n.locale}${item.link}`
                ? '#1F3C87'
                : '#00a4b3'
              : _vm.$route.path == item.link
              ? '#1F3C87'
              : '#00a4b3'
            : _vm.$route.path.includes('/profile') && _vm.$route.query.tab
            ? item.link.includes(_vm.$route.query.tab)
              ? '#1F3C87'
              : '#00a4b3'
            : _vm.$route.path.includes(item.link)
            ? '#1F3C87'
            : '#00a4b3'}}),_c('p',[_vm._v(" "+_vm._s(item.name)+" ")])],1)])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }