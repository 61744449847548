<template>
  <div class="dashboard dashboard-patient">
    <div class="dashboard__sidebar">
      <sidebar @clickProfile="clickProfile()"></sidebar>
    </div>
    <div class="dashboard__main">
      <div class="dashboard__topbar">
        <!-- <div class="d-none d-md-flex flex-grow-1 align-center">
          <span 
            class="role f12"
            :class="{'w100':$i18n.locale == 'en', 'w150':$i18n.locale == 'id'}"
          >{{ $t("role_as") }}:</span>
          <tab />
        </div>
        <topbar /> -->
        <div class="d-flex">
          <div
            @click="() => $router.go(-1)"
            class="d-flex align-center mr-1 mr-md-3"
          >
            <icons
              icon="arrow-back"
              color="#00A4B3"
              class="arrow-back d-flex align-center ml-n2 ml-md-n3"
            />
          </div>
          <div class="d-flex align-center mr10">
            <v-autocomplete
              v-model="payload.serviceName"
              :items="listService"
              item-value="id"
              :item-text="
                $i18n.locale == 'id' ? `name` : `name_${$i18n.locale}`
              "
              class="inp-text service-search"
              rounded
              dense
              prepend-icon="mdi-magnify"
              append-icon=""
              default=""
              ref="serviceName"
              :placeholder="$t('page.nearme.search_nearme')"
            >
            </v-autocomplete>
          </div>
        </div>
        <div class="d-flex">
          <button-language class="d-none d-md-flex ml-5 center" />
          <Cart class="ml-3 mr-3 mr-md-0" />
          <notification class="d-none d-md-flex" />
          <div
            @click="openDialogNotif()"
            class="d-block d-md-none align-center"
          >
            <v-badge
              color="pink"
              :content="$store.state.notifLength"
              :value="$store.state.notifLength"
              class="badge"
              v-if="$store.state.notifLength != ''"
            >
              <icons
                icon="bell"
                color="#00A4B3"
                class="icon d-md-none d-lg-none mt-1"
              />
            </v-badge>
            <icons
              icon="bell"
              color="#00A4B3"
              class="icon d-md-none d-lg-none mt-1"
              v-else
            />
          </div>
        </div>
      </div>
      <div
        class="dashboard__body"
        :class="{ 'dashboard__body--dense': $route.path != '/patient/profile' }"
      >
        <router-view :key="$route.path" />
        <Footer class="mb-lg-0 mb-md-0"></Footer>
      </div>
      <!-- <div class="dashboard__footer">
        <Footer></Footer>
      </div> -->
    </div>
    <bottom-nav :menu="listMenu" />
  </div>
</template>

<script>
import Sidebar from "@/components/v2/sidebar/index.vue";
import Footer from "@/components/v2/footer/index.vue";
import BottomNav from "@/components/v2/nav/bottom_nav.vue";
// import Tab from "@/components/v2/switch_account/tab.vue";
// import Topbar from "@/components/v2/topbar/index.vue";
import notification from "@/components/v2/notification/index.vue";
import ButtonLanguage from "@/components/v2/button/button_language.vue";
// import Pusher from "pusher-js";
import Auth from "@/service/auth_service";
import API from "@/service/api_service.js";
import icons from "@/components/elements/Icons.vue";
import Cart from "@/components/headers/Cart.vue";

export default {
  components: {
    Sidebar,
    Footer,
    // Tab,
    // Topbar,
    BottomNav,
    notification,
    ButtonLanguage,
    icons,
    Cart,
  },
  data: () => ({
    listMenu: [],
    payload: {
      serviceName: [],
    },
    listService: [],
    showNavbar: true,
    showCart: true,
    showCartDesktop: true,
    showSearch: true,
    showLogo: false,
  }),
  watch: {
    "payload.serviceName": function (itemId) {
      if (itemId != "") {
        this.$router.push(
          `/${this.$i18n.locale}/patient/test/${itemId}?branch=`
        );
      }
    },
  },
  created() {
    this.getProduct();
  },
  async mounted() {
    this.listMenu = [
      {
        name: this.$t("menu.home"),
        icon: "home",
        link: "/patient",
      },
      {
        name: this.$t("tab.my_booking"),
        icon: "icon-booking",
        link: "/patient/profile?tab=order",
      },
      {
        name: this.$t("menu.buy_lab_test"),
        icon: "test",
        link: "/patient/test",
      },
      {
        name: this.$t("page.profile.medical_record"),
        icon: "icon-history",
        link: "/patient/profile?tab=medical",
      },
      {
        name: this.$t("menu.account"),
        icon: "user",
        link: "/patient/profile",
      },
    ];
    if (Auth.checkSession()) {
      this.$store.dispatch("fetchNotification");
    }
  },
  methods: {
    clickProfile() {
      if (!this.$route.path.includes("/patient/profile"))
        this.$router.push(`/${this.$i18n.locale}/patient/profile`);
    },
    async getProduct() {
      try {
        const resp = await API.get(
          `${process.env.VUE_APP_API_TRUST}product/get`,
          {
            Authorization: `Bearer ${Auth.getToken()}`,
          }
        );
        if (resp.statusCode === 200) {
          this.listService = resp.results;
        }
      } catch (error) {
        console.log(error);
      }
    },
    openDialogNotif() {
      this.dataDialogNotif = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/color.scss";
.dashboard {
  background: $gray_1;
  display: flex;
  height: 100vh;
  width: 100vw;
  box-sizing: border-box;
  @media screen and (max-width: 960px) {
    background: white;
    flex-direction: column;
  }
  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: $gray_1;
    min-width: 200px;
    position: relative;
    @media screen and (max-width: 960px) {
      // margin-top: 60px;
      background: white;
    }
  }
  &__sidebar {
    background: white;
    @media screen and (max-width: 960px) {
      position: fixed;
      left: 0;
      top: 0;
      width: 100vw;
      z-index: 9;
    }
  }
  &__topbar {
    background: white;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    //justify-content: flex-end;
    justify-content: space-between;
    box-sizing: border-box;
    // margin-bottom: 15px;
    h4 {
      color: $main_2;
      margin-right: 8px;
    }
    @media screen and (max-width: 960px) {
      display: none;
    }
  }
  &__body {
    flex: 1;
    background: $gray_1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // padding: 20px 20px;
    &--dense {
      padding: 0;
    }
    @media screen and (max-width: 960px) {
      overflow: inherit;
      padding: 24px 0;
      &--dense {
        padding: 0;
      }
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
  // &__footer {
  // }
}

@media screen and (max-width: 767px) {
  .dashboard__topbar {
    display: none;
  }

  .footer {
    margin-bottom: 45px;
  }
}
.button-register {
  margin-top: 40px;
  background: #00a4b3 !important;
  border-radius: 12px;
  padding: 10px 24px;
  color: white !important;
  height: 45px !important;
  min-width: 130px !important;
}
.image-profie {
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 35px;
  object-fit: cover;
  padding: 10px;
}
.card--image {
  padding: 15px 15px;
  //   margin-top: 45px;
}
.center {
  align-items: center;
}
.inp-text {
  background: #e1e8f5;
  border-radius: 10px;
  height: 40px;
  padding: 4px;
  font-size: 12px !important;
  width: 450px;
}
.mr10 {
  margin-right: 35% !important;
}
.notification {
  height: 64px !important;
}
.notification {
  margin-right: 20px !important;
  margin-left: 10px !important;
}
.f12 {
  color: #00a4b3;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
}
.w100 {
  width: 100px;
}
.w150 {
  width: 150px;
}
.arrow-back {
  cursor: pointer;
}

@media screen and (min-width: 1282px) {
  // .center{
  //   margin-left: 20% !important;
  // }
  .notification {
    padding-left: 10px;
    padding-right: 10px;
    width: unset !important;
    margin-left: 0 !important;
  }
}

@media screen and (width: 1024px) {
  // .inp-text {
  //   width: 250px !important;
  // }
  .mr10 {
    margin-right: 30% !important;
  }
}

@media (min-width: 1282px) and (max-width: 1400px) {
  .mr10 {
    margin-right: 35% !important;
  }
}
@media (min-width: 1401px) and (max-width: 1500px) {
  .mr10 {
    margin-right: 45% !important;
  }
}
@media (min-width: 1501px) and (max-width: 1600px) {
  .mr10 {
    margin-right: 50% !important;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .mr10 {
    margin-right: 55% !important;
  }
}

@media (min-width: 1801px) and (max-width: 2000px) {
  .mr10 {
    margin-right: 60% !important;
  }
}

@media (min-width: 2001px) {
  .mr10 {
    margin-right: 65% !important;
  }
}
</style>
